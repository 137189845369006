@font-face {
  font-family: 'hindawi-icons';
  src: url('./hindawi-icons.eot?moec85');
  src:
    url('./hindawi-icons.eot?moec85#iefix') format('embedded-opentype'),
    url('./hindawi-icons.ttf?moec85') format('truetype'),
    url('./hindawi-icons.woff?moec85') format('woff'),
    url('./hindawi-icons.svg?moec85#hindawi-icons') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^='icn_'],
[class*=' icn_'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'hindawi-icons' !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icn_icn_arrowEndLeft:before {
  content: '\e916';
}
.icn_icn_caretRight:before {
  content: '\e917';
}
.icn_icn_caretUp:before {
  content: '\e918';
}
.icn_icn_warning:before {
  content: '\e919';
}
.icn_icn_collapse:before {
  content: '\e914';
}
.icn_icn_expand:before {
  content: '\e915';
}
.icn_icn_arrowLeft:before {
  content: '\e911';
}
.icn_icn_arrowEnd:before {
  content: '\e912';
}
.icn_icn_checks:before {
  content: '\e913';
}
.icn_icn_remove:before {
  content: '\e907';
}
.icn_icn_info:before {
  content: '\e908';
}
.icn_icn_tooltip:before {
  content: '\e909';
}
.icn_icn_downloadZip:before {
  content: '\e90a';
}
.icn_icn_checkedBox:before {
  content: '\e90b';
}
.icn_icn_moreDefault:before {
  content: '\e90c';
}
.icn_icn_check:before {
  content: '\e90d';
}
.icn_icn_resend:before {
  content: '\e90e';
}
.icn_icn_caretLeft:before {
  content: '\e90f';
}
.icn_icn_caretDown:before {
  content: '\e910';
}
.icn_icn_save:before {
  content: '\e900';
}
.icn_icn_delete:before {
  content: '\e901';
}
.icn_icn_move:before {
  content: '\e902';
}
.icn_icn_link:before {
  content: '\e903';
}
.icn_icn_edit:before {
  content: '\e904';
}
.icn_icn_download:before {
  content: '\e905';
}
.icn_icn_preview:before {
  content: '\e906';
}
.icn_icn_remove1:before {
  content: '\e91a';
}
.icn_icn_bredcrumbs2:before {
  content: '\e91b';
}
.icn_icn_reports:before {
  content: '\e91c';
}
.icn_icn_manuscripts:before {
  content: '\e91d';
}
.icn_icn_dashboard:before {
  content: '\e91e';
}
.icn_icn_calendar:before {
  content: '\e923';
}
.icn_icn_search:before {
  content: '\e925';
}
.icn_icn_addUser:before {
  content: '\e91f';
}
.icn_icn_arrowRight:before {
  content: '\e920';
}
.icn_icn_withdraw:before {
  content: '\e926';
}
.icn_icn_reassign:before {
  content: '\e927';
}
.icn_icn_lead:before {
  content: '\e928';
}
.icn_icn_solidLead:before {
  content: '\e929';
}
.icn_icn_solidInfo:before {
  content: '\e921';
}
